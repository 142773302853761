<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © 2015 - {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.hipole.com"
        target="_blank"
      >嗨跑赛车</b-link>
      <span class="d-none d-sm-inline-block">, 版权所有</span>
    </span>

    <span class="float-md-right d-none d-md-block">请勿马路撒野，飙车请上赛道
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
